const sendNaverConversion = (type: string) => {
  if (window.wcs) {
    //  이벤트 전송
    const _conv = {
      type: type,
    }

    window.wcs.trans(_conv)
  }
}

export default sendNaverConversion
